<template>
  <div>
    <AppBar :title="$t('users.title')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-title>
        <v-btn depressed color='secondary' to='/users/add' rounded>{{ $t('users.add') }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols='4'>
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('customers.searchPlaceholder')" single-line clearable hide-details></v-text-field>
          </v-col>
          <v-col cols='4'>
            <v-select v-if="roles" v-model="role" :items="roles" item-text="name" item-value="id" :label="$t('users.role')" clearable></v-select>
          </v-col>
          <v-col cols='4'>
            <v-select v-if="countries" v-model="country" :items="countries" item-text="name" item-value="id" :label="$t('users.country')" clearable></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="25"
      :loading="loading"
      :search="search">
        <template v-slot:item.active="{ item }">
          <v-switch v-model='item.isActive' @change='changeStatus(item)' color='secondary' class='mt-0' hide-details></v-switch>
        </template>
        <template v-slot:item.edit="{ item }">
          <v-btn depressed @click="$router.push({ name: 'EditUser', params: { id: item.id } })" rounded>{{ $t('users.edit')}}</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'Users',
  data () {
    return {
      loading: false,
      users: [],
      roles: null,
      role: null,
      country: localStorage.getItem('tenantValue'),
      search: '',
      countries: [
        {
          id: 'PL',
          name: this.$t('countries.pl')
        },
        {
          id: 'BackOffice',
          name: this.$t('countries.bo')
        },
        {
          id: 'CZ',
          name: this.$t('countries.cz')
        },
        {
          id: 'HU',
          name: this.$t('countries.hu')
        }
      ],
      headers: [
        {
          text: this.$t('users.headers.name'),
          value: 'name'
        },
        {
          text: this.$t('users.headers.surname'),
          value: 'surname'
        },
        {
          text: this.$t('users.headers.role'),
          value: 'role.name'
        },
        {
          text: this.$t('users.headers.country'),
          value: 'countryCode'
        },
        {
          text: this.$t('users.headers.email'),
          value: 'email'
        },
        {
          text: this.$t('users.headers.active'),
          value: 'active'
        },
        {
          sortable: false,
          align: 'end',
          width: '100',
          value: 'edit'
        }
      ]
    }
  },
  async created () {
    await this.getUsers()
    await this.getRoles()
  },
  methods: {
    async getRoles () {
      this.loading = true

      try {
        const { data } = await Service.get('Employees/Roles')
        this.roles = data.filter(role => role.id !== 3)
      } catch (error) {}

      this.loading = false
    },
    async getUsers () {
      this.loading = true
      let url = '?'

      if (this.role) {
        url += `&role=${this.role}`
      }
      if (this.country) {
        url += `&countryCode=${this.country}`
      }
      try {
        const { data } = await Service.get(`/Employees${url}`)
        this.users = data.map(user => ({ ...user, isActive: !user.blocked }))
      } catch (error) {}
      this.loading = false
    },
    async changeStatus (user) {
      if (user.blocked) {
        await this.unblockUser(user.id)
      } else {
        await this.blockUser(user.id)
      }
    },
    async blockUser (id) {
      this.loading = true
      await Service.put(`/Employees/${id}/Block`)
      await this.getUsers()
      this.loading = false
    },
    async unblockUser (id) {
      this.loading = true
      await Service.put(`/Employees/${id}/Unblock`)
      await this.getUsers()
      this.loading = false
    }
  },
  watch: {
    role () {
      this.getUsers()
    },
    country () {
      this.getUsers()
    }
  }
}
</script>
